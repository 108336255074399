.testimonial__wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin: 10vw 0;
  border-radius: 10px;
  padding: 10vw 5vw;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0px 3px 5.3px rgba(0, 0, 0, 0.048), 2px -2px 10px rgba(0, 0, 0, 0.06),
    -1px 9px 17.9px rgba(0, 0, 0, 0.072);
  & * {
    margin: 3vw 1vw;
  }
}

.testimonial-copy__wrap {
}

.testimonial-name {
  font-family: "Teko", sans-serif;
  font-weight: bold;
  line-height: 100%;
  font-size: 8vw;
}

.testimonial-relationship {
  font-family: "Teko", sans-serif;
  font-size: 6vw;
  line-height: 100%;
}

.testimonial-quote {
  font-size: 4vw;
}

.testimonial-image__wrap {
  width: 35vw;
  height: 35vw;
  margin: 0 auto 5vw;
}

.testimonial-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

@media #{$mq-medium} {
  .testimonials__wrap {
    width: 80%;
    margin: 0 auto;
  }

  .testimonial__wrap {
    margin: 5vw 0;
    border-radius: 20px;
    padding: 3vw;
    flex-direction: row;
    & * {
      margin: 0.5vw;
    }
  }

  .testimonial-copy__wrap {
    width: 70%;
    padding: 0 2vw;
  }

  .testimonial-name {
    font-size: 4vw;
  }

  .testimonial-relationship {
    font-size: 3vw;
  }

  .testimonial-quote {
    font-size: 1.25vw;
  }

  .testimonial-image__wrap {
    width: 16vw;  
    height: 16vw;
    margin: 0 auto;
  }
}
