.project__wrap {
  width: 90%;
  min-height: 40vw;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0px 3px 5.3px rgba(0, 0, 0, 0.048), 2px -2px 10px rgba(0, 0, 0, 0.06),
    -1px 9px 17.9px rgba(0, 0, 0, 0.072);
  margin: 10vw auto;
}

.project-image__wrap {
  width: 100%;
  height: 30vw;
}

.project-image {
  background-size: cover;
  height: 100%;
  width: 100%;
}

.project-copy__wrap {
  padding: 3vw;
}

.project-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  text-decoration: none;
  color: black;
}

@media #{$mq-medium} {
  .work__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .project__wrap {
    width: 40%;
    min-height: 25vw;
    margin: 2vw auto;
  }

  .project-image__wrap {
    height: 20vw;
  }

  .project-image {
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .project-copy__wrap {
    padding: 2vw;
  }

  .project-name {
    font-size: 1.5vw;
  }
}
