.skill__wrap {
  width: 90%;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0px 3px 5.3px rgba(0, 0, 0, 0.048), 2px -2px 10px rgba(0, 0, 0, 0.06),
    -1px 9px 17.9px rgba(0, 0, 0, 0.072);
  margin: 10vw auto;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.skill-title {
  font-size: 6vw;
}

.skill-duration {
  font-size: 4vw;
}

.skill-icon {
  height: 7vw;
  width: 7vw;
  margin: 2vw auto;
  & svg {
    width: 100%;
    height: 100%;
  }
}

@media #{$mq-medium} {
  .skills__wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5vw;
  }

  .skill__wrap {
    width: 30%;
    margin: 1vw;
    padding: 3vw;
  }

  .skill-title {
    font-size: 2vw;
  }

  .skill-duration {
    font-size: 1.5vw;
  }
}
