.about-image__wrap {
  width: 100%;
  bottom: 0%;
  right: 0;
  position: absolute;
}

.about-image {
  width: 100%;
  display: block;
}

.about-copy__wrap {
  margin-bottom: 50vw;
}

.about-page-titles__wrap {
  display: flex;
  flex-direction: column;
}

.about-page-title {
  font-size: 7vw;
  margin: 1vw;
}

.about-page__copy {
  font-size: 4vw;
  margin: 5vw;
}

@media #{$mq-medium} {
  .about-page-titles__wrap {
    justify-content: space-around;
    flex-direction: row;
  }

  .about-page-title {
    font-size: 3vw;
  }

  .about-page__copy {
    font-size: 2vw;
    margin: 2vw;
  }

  .about-copy__wrap {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 25vw;
  }

  .about-image__wrap {
    width: 50%;
    right: unset;
    transform: translateX(25%);
  }
}
