@import "./styles/reset.scss";
@import "./constants/constants.scss";

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.swiper-wrap {
  font-family: "Teko", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  left: 50%;
  z-index: 1;
  animation-duration: 5s;
  animation-name: example;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  &:hover {
    animation-play-state: paused;
  }
}

@keyframes example {
  0% {
    transform: translate(-50%, 100px);
  }
  50% {
    transform: translate(-50%, 75px);
  }
  100% {
    transform: translate(-50%, 100px);
  }
}

.slide {
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  border: 5px solid black;
  &:nth-of-type(2n) {
    background-color: black;
    border: 5px solid white;
    color: white;
  }
}

.slide-copy {
  cursor: pointer;
  font-size: 35px;
}

.swiper-container {
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-top: auto;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.modal-screen__wrap {
  width: 100vw;
  height: 100%;
  overflow: scroll;
  background-color: white;
  font-size: 5vw;
  position: relative;
  transform: translate(0, -100vh);
  transition: all 0.5s;
  z-index: 2;
}

.skills-modal {
  transform: translate(0, -100vh);
}

.about-modal {
  transform: translate(0, 100vh);
}

.work-modal {
  transform: translate(-100vw, 0);
}

.testimonials-modal {
  transform: translate(100vw, 0);
}

.modal-inner__wrap {
  width: 80%;
  margin: 0 auto;
}

.modal-content__wrap {
  padding: 5%;
  min-height: 100vh;
  position: relative;
}

.modal-title {
  font-family: "Teko", sans-serif;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10vw;
  line-height: 140%;
}

.close-wrap {
  position: absolute;
  z-index: 4;
  right: 10%;
  top: 4%;
  height: 8vw;
  width: 8vw;
  &:before,
  &:after {
    position: absolute;
    content: " ";
    height: 8vw;
    width: 5px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.show {
  transform: translateY(0vw);
}

.box-image {
  background-image: url("./assets/box.svg");
  background-size: 100%, 100%;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.box-background {
  position: absolute;
  background-color: #eee;
  height: 100vh;
  width: 100vw;
}

.home-about__wrap {
  position: absolute;
  bottom: 20%;
  right: 50%;
  perspective: 900px;
  font-size: 8vw;

  & h3 {
    font-weight: bold;
    position: absolute;
    transform-style: preserve-3d;
    transform: rotateX(65deg) translateX(-50%);
  }
}

@media #{$mq-medium} {
  .modal-title {
    font-size: 5vw;
  }

  .slide {
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }

  .close-wrap {
    height: 4vw;
    width: 4vw;
    top: 8%;
    &:before,
    &:after {
      height: 4vw;
      width: 5px;
    }
  }

  .home-about__wrap {
    bottom: 20%;
    font-size: 5vw;
  }
}

@import "./styles/testimonials.scss";
@import "./styles/about.scss";
@import "./styles/work.scss";
@import "./styles//skills.scss";
